import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UsersService } from 'src/app/services/users.service';
import { getTokenFromRoute } from 'src/app/utils/helpers';
import { CustomValidators } from 'src/app/utils/validators';
import { FormValidation } from 'src/app/utils/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent extends FormValidation implements OnInit, OnDestroy {

  public resetFormGroup: FormGroup;
  public resetSubmitAttempt = false;
  private activationToken: string;
  private subscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private router: Router,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.resetFormGroup = this.formBuilder.group({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, {
      validator: CustomValidators.mustMatch('newPassword', 'confirmPassword')
    });
    this.activationToken = getTokenFromRoute(this.activatedRoute);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onResetSubmit(): void {
    this.resetSubmitAttempt = true;
    if (this.resetFormGroup.valid) {
      const newPassword = this.resetFormGroup.value.newPassword;
      this.resetForm(this.resetFormGroup, 'resetSubmitAttempt');
      this.subscription = this.usersService.resetPassword(newPassword, this.activationToken).subscribe(
       (result): void => {
          this.toastrService.success('Your password has been changed.', 'Change password');
          this.router.navigate(['/main/signin']);
        },
        (err: HttpErrorResponse): void => {
          this.toastrService.error('Please try again.', 'Something went wrong!');
        }
      );
    }
  }

}
