import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ResultModel } from '../models/result.model';

export abstract class BaseRestService<T> {
  protected constructor(protected http: HttpClient) {}

  protected create<Type>(endpoint: string, item: Type): Observable<ResultModel<T>> {
    return this.http.post<ResultModel<T>>(`${environment.api}${endpoint}/`, item);
  }

  protected postbyType<Type>(endpoint: string, item: any): Observable<Type> {
    return this.http.post<Type>(`${environment.api}${endpoint}/`, item);
  }

  protected getByPagination(endpoint: string, params?: HttpParams): Observable<ResultModel<T>> {
    return this.http.get<ResultModel<T>>(`${environment.api}${endpoint}/`, {
      params
    });
  }

  protected getByType<Type>(endpoint: string, params?: HttpParams): Observable<Type> {
    return this.http.get<Type>(`${environment.api}${endpoint}/`, {params});
  }

  protected getById(endpoint: string, id: number): Observable<T> {
    return this.http.get<T>(`${environment.api}${endpoint}/${id}/`);
  }

  protected editPatch<Type>(endpoint: string, id: number, data: any): Observable<Type> {
    return this.http.patch<Type>(`${environment.api}${endpoint}/${id}/`, data);
  }

  protected editPut<Type>(endpoint: string, id: number, data: T): Observable<Type> {
    return this.http.put<Type>(`${environment.api}${endpoint}/${id}/`, data);
  }
}
