import { FormGroup } from '@angular/forms';

/**
 * @description Custom validators for form fields
 * @export
 * @abstract
 * @class CustomValidators
 */
export abstract class CustomValidators {

  /**
   * @description Checks if values of two fields are matching.
   * @static
   * @param {string} controlName
   * @param {string} matchingControlName
   * @returns
   * @memberof CustomValidators
   */
  public static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
