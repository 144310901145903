import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IFilterMultiple, IFilterConfig } from './products-filter.config';
import { ICountry } from 'src/app/models/country.model';
import { ICity } from 'src/app/models/city.model';
import { ProductsService } from 'src/app/services/products.service';
import { ResultModel } from 'src/app/models/result.model';
import { ProductsFilterService } from 'src/app/services/products-filter.service';

@Component({
  selector: 'app-products-filter',
  templateUrl: './products-filter.component.html'
})
export class ProductsFilterComponent implements OnInit, OnDestroy {

  private filterSubscription: Subscription;
  private translateSubscription: Subscription;
  private filterOpenedSubscription: Subscription;
  private lang = this.translate.currentLang;
  private countryList: ICountry[] = [];
  private cityListInitial: ICity[] = [];
  public filterOpened = false;
  public filterConfig: IFilterConfig;
  public city = null;
  public showReset = {
    types: false,
    colors: false,
    flavors: false
  };
  private flag = true;

  @Input() parentForm: FormGroup;
  constructor(
    private translate: TranslateService,
    private productsService: ProductsService,
    private formBuilder: FormBuilder,
    private productsFilterService: ProductsFilterService
  ) {
    this.filterConfig = this.productsFilterService.filterConfig;
    this.filterOpenedSubscription = this.productsFilterService.filterOpenedSubject.subscribe(
      (result: boolean): void => {
        this.filterOpened = result;
      }
    );
    this.translateSubscription = this.translate.onLangChange.subscribe(
      (gen: any): void => {
        this.lang = gen.lang;
        this.filterConfig.country = this.translateCountryCityNames<ICountry>(this.countryList, this.lang);
        this.filterConfig.city = this.translateCountryCityNames<ICity>(this.filterConfig.city, this.lang);
        if (this.flag) {
          this.flag = false;
          this.getFilterConfig();
        }
      }
    );
  }

  ngOnInit(): void {
    if (this.flag) {
      this.flag = false;
      this.getFilterConfig();
    }
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
    if (this.filterOpenedSubscription) {
      this.filterOpenedSubscription.unsubscribe();
    }
  }

  public toggleFilter(): void {
    this.filterOpened = !this.filterOpened;
    this.productsFilterService.filterOpened = this.filterOpened;
  }

  public onClickedOutside(): void {
    this.filterOpened = false;
  }

  public get isClickOutsideEnabled(): boolean {
    return window.innerWidth < 992 && this.filterOpened;
  }

  public onCountrySelect(): void {
    this.city = null;
    const countryId = this.parentForm.value.country;
    if (countryId) {
      this.filterConfig.city = this.productsFilterService.countries[countryId].cities;
    } else {
      this.filterConfig.city = [];
    }
    this.onProductFilter(true);
  }

  public onProductFilter(pageSizeChanged?: boolean, changedControl: string = null): void {
    this.productsFilterService.filterSubject.next(pageSizeChanged);
    this.showReset[changedControl] = changedControl && (this.parentForm.controls[changedControl].value as boolean[]).includes(true);
  }

  public onChecklistReset(controls: string): void {
    (this.parentForm.get(controls) as FormArray).controls.forEach((control: AbstractControl): void => {
      control.patchValue(null);
    });
    this.onProductFilter(true, controls);
  }

  private getFilterConfig(): void {
    this.filterSubscription = this.productsService.getFilterOptions().subscribe(
      ([productTypes, productColors, productFlavors, countries, cities]): void => {
        this.countryList = countries.results.map((country: ICountry): ICountry => {
          country.cities = [];
          this.productsFilterService.countries[country.id] = country;
          return country;
        });
        this.cityListInitial = cities.results.map((city: ICity): ICity => {
          this.productsFilterService.cities[city.id] = city;
          this.productsFilterService.countries[city.country.id].cities.push(city);
          return city;
        });
        this.filterConfig = {
          ...this.filterConfig,
          productTypes: productTypes as ResultModel<IFilterMultiple>,
          productColors: productColors as ResultModel<IFilterMultiple>,
          productFlavors: productFlavors as ResultModel<IFilterMultiple>,
          country: this.countryList,
          city: []
        };
        this.productsFilterService.filterConfig = this.filterConfig;
        this.parentForm.addControl('types', this.buildCheckboxes(this.filterConfig.productTypes.results));
        this.parentForm.addControl('colors', this.buildCheckboxes(this.filterConfig.productColors.results));
        this.parentForm.addControl('flavors', this.buildCheckboxes(this.filterConfig.productFlavors.results));
        this.flag = true;
      }
    );
  }

  private translateCountryCityNames<Type>(places: Type[], lang: string): Type[] {
    return places.map((place: Type): Type => {
      (place as any).label = place[`name_${lang}`];
      return place;
    });
  }

  private buildCheckboxes(config: IFilterMultiple[]): FormArray {
    const arr: FormControl[] = config.map((conf: IFilterMultiple): FormControl => {
      return this.formBuilder.control(null);
    });
    return this.formBuilder.array(arr);
  }

  public get getTypes(): FormArray {
    return this.parentForm.get('types') as FormArray;
  }

  public get getColors(): FormArray {
    return this.parentForm.get('colors') as FormArray;
  }

  public get getFlavors(): FormArray {
    return this.parentForm.get('flavors') as FormArray;
  }

}
