import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';

import { BaseRestService } from './base-rest.service';
import { UserModel, ReviewUser } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseRestService<UserModel> {

  public authenticatedSubject = new Subject<boolean>();

  constructor(
    protected http: HttpClient,
    private jwtHelper: JwtHelperService
  ) {
    super(http);
  }

  public getToken(): string {
    const token = localStorage.getItem('access_token');
    return !this.tokenExpired(token) && token;
  }

  public setToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  public setUser(user: ReviewUser): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public tokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

  public getUserId(): number {
    if (this.isAuthenticated) {
      return this.jwtHelper.decodeToken(this.getToken()).user_id;
    }
  }

  public get user(): ReviewUser {
    if (this.isAuthenticated) {
      return JSON.parse(localStorage.getItem('user'));
    }
  }

  public get isAuthenticated(): boolean {
    const token = this.getToken();
    return token ? !this.tokenExpired(token) : false;
  }

  public login(user: UserModel): Observable<any> {
    return this.create('users/login', user);
  }

  public logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    this.authenticatedSubject.next(false);
  }
}
