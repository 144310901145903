import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ReviewsService } from 'src/app/services/reviews.service';
import { ReviewModel } from 'src/app/models/review.model';
import { ResultModel } from 'src/app/models/result.model';
import { routeAnimation } from 'src/app/animations/route.animation';
import { AuthService } from 'src/app/services/auth.service';
import { fadeAnimation } from 'src/app/animations/fade.animation';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  animations: [
    routeAnimation,
    fadeAnimation
  ]
})
export class ReviewsComponent implements OnInit, OnDestroy {

  public reviewsPerPage = 16;
  public reviewsCount: number;
  public page = 1;
  public paginationMaxSize = 5;
  private reviewsSubscription: Subscription;
  public reviews: ReviewModel[] = [];

  constructor(
    private reviewsService: ReviewsService,
    private authService: AuthService
  ) { }

  @HostBinding('@routeAnimations') hostAnimation: any;

  ngOnInit(): void {
    if (window.outerWidth < 768) {
      this.paginationMaxSize = 5;
    } else {
      this.paginationMaxSize = 7;
    }
    this.reviewsSubscription = this.reviewsService.getReviews(this.page, this.reviewsPerPage).subscribe(
      (result: ResultModel<ReviewModel>): void => {
        this.reviewsCount = result.count;
        this.reviews = result.results;
        // if (this.authService.isAuthenticated) {
        //   this.reviews = result.results.map((review: ReviewModel) => {
        //     if (review.user.id === this.authService.getUserId()) {
        //       review.isMine = true;
        //       return review;
        //     }
        //   });
        // }
      },
      (err: HttpErrorResponse): void => {
        this.reviewsCount = 0;
        this.reviews = [];
      }
    );
  }

  ngOnDestroy(): void {
    this.reviewsSubscription.unsubscribe();
  }

  public getPage($event: number): void {
    this.page = $event;
    window.scrollTo(0, 0);
    this.reviewsSubscription = this.reviewsService.getReviews(this.page, this.reviewsPerPage).subscribe(
      (result: ResultModel<ReviewModel>): void => {
        this.reviewsCount = result.count;
        this.reviews = result.results;
      },
      (err: HttpErrorResponse): void => {
        this.reviewsCount = 0;
        this.reviews = [];
      }
    );
  }

}
