import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseRestService } from './base-rest.service';
import { ProductModel } from '../models/product.model';
import { ResultModel } from '../models/result.model';
import { IParam } from '../models/param.model';
import { anyToHttpParams } from '../utils/helpers';
import { IFilterMultiple } from '../components/main/products/products-filter/products-filter.config';
import { ICountry } from '../models/country.model';
import { ICity } from '../models/city.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseRestService<ProductModel> {

  public searchSubject = new Subject<string>();

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getAllProductsByPagination(params?: IParam): Observable<ResultModel<ProductModel>> {
    return this.getByPagination('wines', anyToHttpParams(params));
  }

  public getProductById(id: number): Observable<ProductModel> {
    return this.getById('wines', id);
  }

  public getFilterOptions(): Observable<ResultModel<IFilterMultiple | ICountry | ICity>[]> {
    return forkJoin(
      this.getByType<ResultModel<IFilterMultiple>>('wine-types'),
      this.getByType<ResultModel<IFilterMultiple>>('wine-colors'),
      this.getByType<ResultModel<IFilterMultiple>>('wine-flavors'),
      this.getByType<ResultModel<ICountry>>('countries'),
      this.getByType<ResultModel<ICountry>>('cities')
    ).pipe(catchError(error => of(error)));
  }

}
