import { Component, Input, HostBinding } from '@angular/core';

import { ProductModel } from 'src/app/models/product.model';
import { routeAnimation } from 'src/app/animations/route.animation';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  animations: [
    routeAnimation
  ]
})
export class ProductComponent {

  @HostBinding('@routeAnimations') hostAnimation: any;
  @Input() productData: ProductModel;

}
