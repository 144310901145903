import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description returns rating value in range [0 - 100].
 * @export
 * @class RatingPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'rating'
})
export class RatingPipe implements PipeTransform {

  transform(value: number): string {
    return value * 100 / 5 + '%';
  }

}
