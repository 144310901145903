import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseRestService } from './base-rest.service';

export interface ActivationObject {
  message: string;
}

@Injectable()
export class ActivateUserService extends BaseRestService<ActivationObject> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  activateUser(tokenObj): Observable<ActivationObject> {
    return this.postbyType<ActivationObject>('users/activate', tokenObj);
  }
}
