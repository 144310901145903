import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
// import { FormGroup, FormBuilder } from '@angular/forms';
// import { HttpErrorResponse } from '@angular/common/http';
// import { ToastrService } from 'ngx-toastr';

import { ReviewModel } from 'src/app/models/review.model';
// import { ReviewsService } from 'src/app/services/reviews.service';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html'
})
export class ProductReviewComponent implements OnInit, OnDestroy {

  // public reviewForm: FormGroup;
  // public editMode = false;
  // private reviewEditSubscription: Subscription;

  @Input() public review: ReviewModel;
  // @ViewChild('comment') public comment: ElementRef;
  // @ViewChild('stars') stars: ElementRef;

  constructor(
    // private renderer: Renderer2,
    // protected formBuilder: FormBuilder,
    // private reviewsService: ReviewsService,
    // private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    // if (this.review && this.review.isMine) {
    //   this.reviewForm = this.formBuilder.group({
    //     comment: new FormControl({ value: this.review.comment, disabled: true}, [Validators.maxLength(255)])
    //   });
    // }
  }

  ngOnDestroy() {
    // if (this.reviewEditSubscription) {
    //   this.reviewEditSubscription.unsubscribe();
    // }
  }

  // public startEdit(): void {
  //   this.editMode = true;
  //   this.reviewForm.controls.comment.enable();
  //   this.comment.nativeElement.focus();
  // }

  // public setRating(rating: number): void {
  //   this.review.rate = rating;
  //   this.renderer.setAttribute(this.stars.nativeElement, 'data-stars', rating + '');
  // }

  // public onEdit(): void {
  //   if (this.reviewForm.valid) {
  //     this.review.comment = this.reviewForm.value.comment;
  //     this.reviewEditSubscription = this.reviewsService.editReview(this.review).subscribe(
  //       (result: ReviewModel): void => {
  //         this.toastrService.success('Your review has been edited', 'Success');
  //         this.reviewForm.controls.comment.disable();
  //         this.editMode = false;
  //       },
  //       (err: HttpErrorResponse): void => {
  //         this.toastrService.error('Please try again.', 'Something went wrong!');
  //         this.editMode = false;
  //       }
  //     );
  //   }
  // }

}
