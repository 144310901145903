import { Injectable } from '@angular/core';
// @ts-ignore
import am from '../../assets/i18n/am.json';
// @ts-ignore
import en from '../../assets/i18n/en.json';

@Injectable({
  providedIn: 'root'
})
export class MyTranslateService {
  constructor() {
  }

  public translate(key: any): string {
    let val = en;
    console.log(window.localStorage.getItem('lang'))
    if (window.localStorage.getItem('lang') === 'am') {
      val = am;
    }
    for (let i = 0; i < key.length; i++) {
      val = val[key[i]];
    }
    return val;
  }
}
