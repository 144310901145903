import { trigger, state, style, transition, animate, AnimationTriggerMetadata } from '@angular/animations';

export function reviewToggleAnimation(triggerName: string, height: string): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state('true', style({
      height: '*'
    })),
    state('false', style({
      height: height,
    })),
    transition('true <=> false', [
      animate('.4s cubic-bezier(0.4, 0, 0.2, 1)')
    ])
  ]);
}
