import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

/**
 * @description translates value on language change.
 * @export
 * @class MyTranslatePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'myTranslate',
  pure: false
})
export class MyTranslatePipe implements PipeTransform, OnDestroy {

  private lang = this.translate.currentLang;
  private subscription: Subscription;

  constructor(private translate: TranslateService) {
    this.subscription = this.translate.onLangChange.subscribe(
      (gen: any): void => {
        this.lang = gen.lang;
      }
    );
  }

  transform(value: any, args?: any): string {
    return value[`${args}_${this.lang}`];
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
