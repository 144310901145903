import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ResultModel } from 'src/app/models/result.model';
import { ProductModel } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';
import { fadeAnimation } from 'src/app/animations/fade.animation';

@Component({
  selector: 'app-home-products',
  templateUrl: './home-products.component.html',
  animations: [fadeAnimation]
})
export class HomeProductsComponent implements OnInit, OnDestroy {

  public products: ProductModel[] = [];
  private productsSubscription: Subscription;

  constructor(
    private productsService: ProductsService
  ) { }

  ngOnInit(): void {
    this.productsSubscription = this.productsService.getAllProductsByPagination({ page_size: '6', list_for_home: '1' }).pipe(
      map((productResults: ResultModel<ProductModel>): ProductModel[] => {
        return productResults.results.map((product: ProductModel): ProductModel => {
          product.sponsored = ((product.sponsored as string).indexOf('true') !== -1);
          return product;
        }).sort((a: ProductModel, b: ProductModel): number => {
          return a.sponsored < b.sponsored ? 1 : a.sponsored > b.sponsored ? -1 : 0;
        });
      })
    ).subscribe(
      (result: ProductModel[]): void => {
        this.products = result;
      },
      (err: HttpErrorResponse): void => {
        this.products = [];
      }
    );
  }

  ngOnDestroy(): void {
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
  }

}
