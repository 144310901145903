import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { MainComponent } from './components/main/main.component';
import { SigninComponent } from './components/main/signin/signin.component';
import { SignupComponent } from './components/main/signup/signup.component';
import { ResetPasswordComponent } from './components/main/reset-password/reset-password.component';
import { AboutComponent } from './components/main/about/about.component';
import { ProductsComponent } from './components/main/products/products.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ProductOverviewComponent } from './components/main/products/product/product-overview/product-overview.component';
import { ContactComponent } from './components/main/contact/contact.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { ProfileComponent } from './components/main/profile/profile.component';
import { ReviewsComponent } from './components/main/reviews/reviews.component';

import { AuthGuard } from './guards/auth.guard';
import { LoginedGuard } from './guards/logined.guard';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'activate-user', component: ActivateUserComponent, canActivate: [LoginedGuard] },
  { path: 'main', component: MainComponent, children: [
    {
      path: '',
      redirectTo: 'products',
      pathMatch: 'full'
    },
    {
      path: 'signin',
      component: SigninComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.SIGNIN'
          }
        ],
        background: 'banner-1.jpg'
      },
      canActivate: [LoginedGuard]
    },
    {
      path: 'signup',
      component: SignupComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.SIGNUP'
          }
        ],
        background: 'banner-1.jpg'
      },
      canActivate: [LoginedGuard]
    },
    {
      path: 'reset-password',
      component: ResetPasswordComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.RESET_PASSWORD'
          }
        ],
        background: 'banner-1.jpg'
      },
      canActivate: [LoginedGuard]
    },
    {
      path: 'about',
      component: AboutComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.ABOUT'
          }
        ],
        background: 'banner-4.jpg'
      }
    },
    {
      path: 'contact',
      component: ContactComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.CONTACT_US'
          }
        ],
        background: 'banner-4.jpg'
      }
    },
    {
      path: 'products',
      component: ProductsComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.PRODUCTS.PRODUCTS'
          }
        ],
        background: 'banner-3.jpg'
      }
    },
    {
      path: 'products/product/:id',
      component: ProductOverviewComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.PRODUCTS.PRODUCTS',
            link: '/main/products'
          },
          {
            title: 'GENERAL.HEADER.PRODUCTS.PRODUCT'
          }
        ],
        background: 'banner-3.jpg'
      }
    },
    {
      path: 'profile',
      component: ProfileComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.PROFILE'
          }
        ],
        background: 'banner-5.jpg'
      },
      canActivate: [AuthGuard]
    },
    {
      path: 'reviews',
      component: ReviewsComponent,
      data: {
        breadcrumbs: [
          {
            title: 'GENERAL.HEADER.REVIEWS'
          }
        ],
        background: 'banner-5.jpg'
      }
    }
  ]},
  { path: '**',  component: NotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
