import { trigger, state, style, transition, animate, AnimationTriggerMetadata } from '@angular/animations';

export function toggleAnimation(triggerName: string, state1: string, state2: string): AnimationTriggerMetadata {
  return trigger(triggerName, [
    state(state1, style({
      opacity: 1,
      height: '*'
    })),
    state(state2, style({
      height: 0,
      opacity: 0
    })),
    transition(`${state1} <=> ${state2}`, [
      animate('.4s cubic-bezier(0.4, 0, 0.2, 1)')
    ])
  ]);
}
