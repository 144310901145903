import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ProductModel } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';
import { routeAnimation } from 'src/app/animations/route.animation';
import { PreviousRouteService } from 'src/app/services/previouse-route.service';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  animations: [
    routeAnimation
  ]
})
export class ProductOverviewComponent implements OnInit, OnDestroy {

  public id: number;
  public productData: ProductModel;
  public prevRoute: string;

  private activatedRouteSubscription: Subscription;
  private productSubscription: Subscription;

  @HostBinding('@routeAnimations') hostAnimation: any;

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private productsService: ProductsService,
    private previousRouteService: PreviousRouteService
  ) {
    this.prevRoute = this.previousRouteService.getPreviousUrl();
  }

  ngOnInit(): void {
    this.activatedRouteSubscription = this.activatedroute.params.subscribe(params => {
      this.id = +params['id'];
      this.productSubscription = this.productsService.getProductById(+params['id']).subscribe(
        (result: ProductModel): void => {
          this.productData = result;
          this.productData.rate = Number(result.rate);
        },
        (err: HttpErrorResponse): void => {
          this.router.navigate(['/main/products']);
        }
      );
    });
  }

  ngOnDestroy(): void {
    if (this.activatedRouteSubscription) {
      this.activatedRouteSubscription.unsubscribe();
    }
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  }

}
