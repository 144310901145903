import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFocusInvalid]'
})
export class FocusInvalidDirective {

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('submit')
  onFormSubmit(): void {
    const firstInvalidField = this.elementRef.nativeElement.querySelector('input.ng-pristine.ng-invalid, input.ng-invalid.ng-touched');
    if (firstInvalidField) {
      firstInvalidField.focus();
    }
  }

}
