import { Options } from 'ng5-slider';

import { ResultModel } from 'src/app/models/result.model';
import { ICountry } from 'src/app/models/country.model';
import { ICity } from 'src/app/models/city.model';

export interface IFilterMultiple {
  readonly type?: string;
  readonly color: string;
  readonly flavor: string;
  readonly id: number;
  selected: boolean;
}

export interface IFilterConfig {
  max_price: number;
  min_price: number;
  min_rate: number;
  max_rate: number;
  min_year: number;
  max_year: number;
  priceRangeOptions: Options;
  ratingRangeOptions: Options;
  yearRangeOptions: Options;
  country: ICountry[];
  city: ICity[];
  productTypes?: ResultModel<IFilterMultiple>;
  productColors?: ResultModel<IFilterMultiple>;
  productFlavors?: ResultModel<IFilterMultiple>;
}

export const productsFilterConfig: IFilterConfig = {
  max_price: 100000,
  min_price: 500,
  min_rate: 0,
  max_rate: 5,
  min_year: 1800,
  max_year: new Date().getFullYear(),
  priceRangeOptions: <Options> {
    floor: 500,
    ceil: 100000,
    translate: (value: number): string => {
      return value + ' ֏';
    }
  },
  ratingRangeOptions: <Options> {
    floor: 0,
    ceil: 5
  },
  yearRangeOptions: <Options> {
    floor: 1800,
    ceil: new Date().getFullYear()
  },
  country: [],
  city: []
};
