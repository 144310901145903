import { Component, Input } from '@angular/core';
import { RouteData } from 'src/app/models/route-data.model';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html'
})
export class MainHeaderComponent {
  @Input() data: RouteData;
}
