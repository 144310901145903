import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { ReviewsService } from 'src/app/services/reviews.service';
import {ReviewModel, ReviewForPost, ReviewModelWithMessage} from 'src/app/models/review.model';
import { ResultModel } from 'src/app/models/result.model';
import {MyTranslateService} from '../../../../../services/my-translate.service';

@Component({
  selector: 'app-product-reviews',
  templateUrl: './product-reviews.component.html'
})
export class ProductReviewsComponent implements OnInit, OnDestroy, AfterViewInit {

  private addReviewSubscription: Subscription;
  private reviewSubscription: Subscription;
  private subscription: Subscription;
  private userReviewSubscription: Subscription;
  public logined: boolean;
  public reviewIsSent: boolean;
  public commentMaxLength = 500;
  private rating: number;
  public isRated = false;
  public reviewForm: FormGroup;
  public reviews: ReviewModel[] = [];
  public reviewsCount = 0;

  @ViewChild('stars') stars: ElementRef;
  @Input() wineId: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private myTranslateService: MyTranslateService,
    private reviewsService: ReviewsService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.subscription = this.authService.authenticatedSubject.subscribe(
      (value: boolean): void => {
        this.logined = value;
      }
    );

    this.logined = this.authService.isAuthenticated;

    this.reviewForm = this.formBuilder.group({
      comment: new FormControl(
        this.reviewsService.review && this.reviewsService.review.wine === this.wineId ? this.reviewsService.review.comment : '',
        [Validators.maxLength(this.commentMaxLength)]
      )
    });

    this.reviewSubscription = this.reviewsService.getReviewsByWineId(this.wineId).subscribe(
      (result: ResultModel<ReviewModel>): void => {
        this.reviewsCount = result.count;
        // if (this.authService.isAuthenticated) {
        //   this.reviews = result.results.map((review: ReviewModel) => {
        //     if (review.user.id === this.authService.getUserId()) {
        //       review.isMine = true;
        //     }
        //     return review;
        //   });
        // } else {
          this.reviews = result.results;
        // }
      },
      (err: HttpErrorResponse): void => {
        this.reviewsCount = 0;
        this.reviews = [];
      }
    );

    if (this.logined) {
      this.userReviewSubscription = this.reviewsService.getUserReviewOnWine(this.authService.getUserId(), this.wineId).subscribe(
        (result: ResultModel<ReviewModel>): void => {
          this.reviewIsSent = result.count ? true : false;
        },
        (err: HttpErrorResponse): void => {}
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.reviewsService.review && this.reviewsService.review.wine === this.wineId && this.reviewsService.review.rate) {
      this.setRating(this.reviewsService.review.rate);
    }
  }

  ngOnDestroy(): void {
    if (this.addReviewSubscription) {
      this.addReviewSubscription.unsubscribe();
    }
    if (this.reviewSubscription) {
      this.reviewSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.userReviewSubscription) {
      this.userReviewSubscription.unsubscribe();
    }
  }

  public setRating(rating: number): void {
    this.rating = rating;
    this.renderer.setAttribute(this.stars.nativeElement, 'data-stars', rating + '');
  }

  public onAddComment(): void {
    this.reviewsService.review = {
      rate: this.rating,
      comment: this.reviewForm.get('comment').value,
      wine: this.wineId
    };
    if (this.authService.isAuthenticated) {
      if (!this.rating) {
        this.isRated = true;
        return;
      }
      if (this.reviewForm.invalid) {
        return;
      }
      this.isRated = false;
      const newReview = new ReviewForPost(
        this.wineId,
        this.authService.getUserId(),
        +this.rating,
        this.reviewForm.value.comment,
      );
      this.addReviewSubscription = this.reviewsService.addReview(newReview).subscribe(
        (result: ReviewModelWithMessage): void => {
          this.reviewIsSent = true;
          result.data.user = this.authService.user;
          this.reviews = [result.data, ...this.reviews];
          this.toastrService.success(
            this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'REVIEW_SEND', 'MESSAGE']),
            this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'REVIEW_SEND', 'TITLE']));
        },
        (): void => {
          this.toastrService.error(this.myTranslateService.translate(['SIGNIN', 'ERROR', 'TITLE']),
            this.myTranslateService.translate(['SIGNIN', 'ERROR', 'MESSAGE']));
        }
      );
    } else {
      this.toastrService.error('You must login first to leave a review!');
      this.router.navigate(['/main/signin']);
    }
  }

}
