import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.pipe(filter((event: any): boolean => event instanceof NavigationEnd)).subscribe((event): void => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }
}
