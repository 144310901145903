import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductModel } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';
import { routeAnimation } from 'src/app/animations/route.animation';
import { fadeAnimation } from 'src/app/animations/fade.animation';
import { ResultModel } from 'src/app/models/result.model';
import { IParam } from 'src/app/models/param.model';
import { ProductsFilterService } from 'src/app/services/products-filter.service';
import { productsFilterConfig } from './products-filter/products-filter.config';
import {chageFilter} from './products-sortbar/products-sort.config';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  animations: [
    routeAnimation,
    fadeAnimation
  ]
})
export class ProductsComponent implements OnInit, OnDestroy {

  private routeSubscription: Subscription;
  private productsSubscription: Subscription;
  private productsFilterSubscription: Subscription;
  private translateSubscription: Subscription;
  public productsPerPage = 16;
  public productsCount: number;
  public page = 1;
  public wineName: string;
  private lang = this.translate.currentLang;
  public products: ProductModel[] = [];
  public filterForm: FormGroup;
  public showAll = false;
  private customFilter = 'true';
  private flag = true;
  @HostBinding('@routeAnimations') hostAnimation: any;

  constructor(
    private productsService: ProductsService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private productsFilterService: ProductsFilterService
  ) {
    this.buildForm();
    this.translateSubscription = this.translate.onLangChange.subscribe(
      (gen: any): void => {
        this.lang = gen.lang;
        if (this.flag) {
          this.flag = false;
          this.loadData();
        }
      }
    );
    this.productsFilterSubscription = this.productsFilterService.filterSubject.subscribe(
      (result: boolean): void => {
        this.onProductFilter(result);
      }
    );
  }

  ngOnInit(): void {
    if (this.flag) {
      this.flag = false;
      this.loadData();
    }
  }

  private loadData(): void {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe(
      (params): void => {
        this.wineName = params && params.wine && params.wine.length ? params.wine : '';
        this.productsSubscription = this.productsService.getAllProductsByPagination({
          page_size: `${this.productsPerPage}`,
          page: `${this.page}`,
          search: this.wineName
        }).pipe(map((productResults: ResultModel<ProductModel>): ProductModel[] => {
          this.productsCount = productResults.count;
          productResults.results = productResults.results.map((product: ProductModel): ProductModel => {
            product.sponsored = ((product.sponsored as string).indexOf('true') !== -1);
            return product;
          });
          return productResults.results;
        })).subscribe(
          (products: ProductModel[]): void => {
            this.products = products;
            this.flag = true;
          },
          (err: HttpErrorResponse): void => {
            this.productsCount = 0;
            this.products = [];
          }
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.productsFilterService.filterConfig = productsFilterConfig;
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
    if (this.productsFilterSubscription) {
      this.productsFilterSubscription.unsubscribe();
    }
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }

  public onProductFilter(pageSizeChanged?: boolean): void {
    if (pageSizeChanged) {
      this.page = 1;
    }
    this.productsPerPage = this.filterForm.value.page_size;
    this.productsSubscription = this.productsService.getAllProductsByPagination(this.filterParams).pipe(
      map((productResults: ResultModel<ProductModel>): ProductModel[] => {
        this.productsCount = productResults.count;
        productResults.results = productResults.results.map((product: ProductModel): ProductModel => {
          product.sponsored = ((product.sponsored as string).indexOf('true') !== -1);
          return product;
        });
        return productResults.results;
    })).subscribe(
      (products: ProductModel[]) => {
        this.products = products;
      },
      (err: HttpErrorResponse) => {
        this.products = [];
        this.productsCount = 0;
      }
    );
  }

  public changeSortValue($event): void {
    this.customFilter = $event;
  }

  public getPage($event: number): void {
    this.page = $event;
    this.onProductFilter();
  }

  private getParamWithLanguage(param: string): string {
    return `${param}_${this.lang}`;
  }

  private get filterParams(): IParam {
    if (this.filterForm.value.sort) {
      this.filterForm.value.sort = chageFilter[`${this.filterForm.value.sort}_${this.customFilter}`];
    }
    const {price, rating, year, city, country, types, colors, flavors, sort, page_size, ...filterPar} = this.filterForm.value;

    const typesArray: number[] = [], colorsArray: number [] = [], flavorsArray: number[] = [];

    types.forEach((type: boolean | string, index: number): void => {
      if (type) {
        typesArray.push(this.productsFilterService.filterConfig.productTypes.results[index].id);
      }
    });

    colors.forEach((color: boolean | string, index: number): void => {
      if (color) {
        colorsArray.push(this.productsFilterService.filterConfig.productColors.results[index].id);
      }
    });

    flavors.forEach((flavor: boolean | string, index: number): void => {
      if (flavor) {
        flavorsArray.push(this.productsFilterService.filterConfig.productFlavors.results[index].id);
      }
    });

    const countryName = country && this.productsFilterService.countries[country][`name_${this.lang}`];
    const cityName = city && this.productsFilterService.cities[city][`name_${this.lang}`];
    this.showAll = (page_size === 'show_all');

    return {
      ...filterPar,
      min_price: this.filterForm.value.price[0],
      max_price: this.filterForm.value.price[1],
      ...rating[0] > 0 ? { min_rate: rating[0], max_rate: rating[1] } : { rate_range: `null,${rating[1]}` },
      min_year: this.filterForm.value.year[0],
      max_year: this.filterForm.value.year[1],
      ...this.showAll ? { show_all: 1 } : { page_size, page: this.page },
      flavor: flavorsArray,
      color: colorsArray,
      type: typesArray,
      search: this.wineName,
      ...sort && { [sort]: 1},
      ...city && { [this.getParamWithLanguage('city__name')]: cityName },
      ...country && { [this.getParamWithLanguage('city__country__name')]: countryName }
    };
  }

  private buildForm(): void {
    this.filterForm = this.formBuilder.group({
      price: new FormControl([this.productsFilterService.filterConfig.min_price, this.productsFilterService.filterConfig.max_price]),
      rating: new FormControl([this.productsFilterService.filterConfig.min_rate, this.productsFilterService.filterConfig.max_rate]),
      year: new FormControl([this.productsFilterService.filterConfig.min_year, this.productsFilterService.filterConfig.max_year]),
      page_size: new FormControl(16),
      sort: new FormControl(),
      city: new FormControl(),
      country: new FormControl()
    });
  }

}
