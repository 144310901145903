import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { routeAnimation } from 'src/app/animations/route.animation';
import { ContcatService } from 'src/app/services/contact.service';
import { FormValidation } from 'src/app/utils/forms';
import { ContactMessageModel } from 'src/app/models/contact-message.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  animations: [routeAnimation]
})
export class ContactComponent extends FormValidation implements OnInit, OnDestroy {

  public contactForm: FormGroup;
  private messageSubscription: Subscription;
  public formSubmitAttempt: boolean;

  @HostBinding('@routeAnimations') ContactComponent: Component;

  constructor(
    private contactMessageService: ContcatService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required])
    });
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  public onContactSubmit(): void {
    this.formSubmitAttempt = true;
    if (this.contactForm.valid) {
      const formValue = this.contactForm.value;
      const contactMessage = new ContactMessageModel(formValue.email, formValue.name, formValue.subject, formValue.message);
      this.messageSubscription = this.contactMessageService.sendMessage(contactMessage).subscribe(
        (result: any): void => {
          this.resetForm(this.contactForm, 'formSubmitAttempt');
          this.toastrService.success('We will get back to you within 24 hours', 'Thank you for your message');
        },
        (error: HttpErrorResponse): void => {
          this.toastrService.error('Please try again.', 'Something went wrong!');
        }
      );
    }
  }

}
