export interface IFilterSort {
  readonly name: string;
  readonly icon: string;
  readonly value: string;
}

export interface IResultCount {
  label: string | number;
  value: string | number;
}

export interface ISortConfig {
  resultCount: IResultCount[];
  sortOptions: IFilterSort[];
}

export const productsSortConfig: ISortConfig = {
  resultCount: [
    {
      label: 16,
      value: 16
    },
    {
      label: 48,
      value: 48
    },
    {
      label: 80,
      value: 80
    },
    {
      label: 'All',
      value: 'show_all'
    }
  ],
  sortOptions: [
    {
      name: 'SORT_CONFIG.PRICE',
      icon: 'fa fa-caret-down',
      value: 'price_high_to_low'
    },
    {
      name: 'SORT_CONFIG.REVIEWS',
      icon: 'fa fa-caret-down',
      value: 'most_reviewed_wine'
    },
    {
      name: 'SORT_CONFIG.RATING',
      icon: 'fa fa-caret-down',
      value: 'rate_high_to_low'
    }
  ]
};

export const chageFilter = {
  price_high_to_low_true:  'price_high_to_low',
  price_high_to_low_false:  'price_low_to_high',
  price_low_to_high_true:  'price_high_to_low',
  price_low_to_high_false:  'price_low_to_high',
  most_reviewed_wine_true:  'most_reviewed_wine',
  most_reviewed_wine_false:  'least_reviewed_wine',
  least_reviewed_wine_true:  'most_reviewed_wine',
  least_reviewed_wine_false:  'least_reviewed_wine',
  rate_high_to_low_true:  'rate_high_to_low',
  rate_high_to_low_false:  'rate_low_to_high',
  rate_low_to_high_true: 'rate_high_to_low',
  rate_low_to_high_false: 'rate_low_to_high',
};
