import { Pipe, PipeTransform } from '@angular/core';

import { UserModel } from '../models/user.model';

/**
 * @description returns user's fullname by given firstname and lastname.
 * @export
 * @class FullnamePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'fullname'
})
export class FullnamePipe implements PipeTransform {

  transform(user: UserModel): string {
    return user && `${user.first_name} ${user.last_name}`;
  }

}
