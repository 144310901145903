import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BaseRestService } from './base-rest.service';
import {ReviewModel, IReview, ReviewForPost, ReviewModelWithMessage} from '../models/review.model';
import { ResultModel } from '../models/result.model';
import { AuthService } from './auth.service';
import { IOperationModel } from '../models/operation.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService extends BaseRestService<ReviewModel> implements OnDestroy {

  public review: IReview;
  private subscription: Subscription;

  constructor(
    protected http: HttpClient,
    private authService: AuthService
  ) {
    super(http);
    this.subscription = this.authService.authenticatedSubject.pipe(filter((value: boolean) => !value)).subscribe(
      (): void => {
        this.review = null;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public addReview(review: ReviewForPost): Observable<ReviewModelWithMessage> {
    return this.postbyType<ReviewModelWithMessage>('reviews', review);
  }

  public getReviews(page?: number, pageSize?: number): Observable<ResultModel<ReviewModel>> {
    const params = new HttpParams().set('page', `${page}`).set('page_size', `${pageSize}`);
    return this.getByPagination('reviews', params);
  }

  public getReviewsByWineId(wineId: number): Observable<ResultModel<ReviewModel>> {
    const params = new HttpParams().set('wine', wineId.toString());
    return this.getByPagination('reviews', params);
  }

  public getUserReviewOnWine(userId: number, wineId: number): Observable<ResultModel<ReviewModel>> {
    const params = new HttpParams().set('wine', wineId.toString()).set('user', userId.toString());
    return this.getByPagination('reviews', params);
  }

  public editReview(review: ReviewModel): Observable<ReviewModel> {
    return this.editPatch<ReviewModel>('reviews', review.id, {
      comment: review.comment,
      rate: review.rate
    });
  }
}
