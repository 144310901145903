import { Component, OnInit, HostBinding, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { UsersService } from 'src/app/services/users.service';
import { UserModel } from 'src/app/models/user.model';
import { routeAnimation } from 'src/app/animations/route.animation';
import { toggleAnimation } from 'src/app/animations/toggle.animation';
import { FormValidation } from 'src/app/utils/forms';
import { CustomValidators } from 'src/app/utils/validators';
import {MyTranslateService} from '../../../services/my-translate.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  animations: [
    routeAnimation,
    toggleAnimation('passwordToggle', 'opened', 'closed')
  ]
})
export class ProfileComponent extends FormValidation implements OnInit, OnDestroy {

  public profileForm: FormGroup;
  public passwordOpened = false;
  private profileSubscription: Subscription;
  private profileEditSubscription: Subscription;
  public profileSubmitAttempt = false;

  private passwordChanged = false;

  constructor(
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private myTranslateService: MyTranslateService,
    private toastrService: ToastrService,
    private renderer: Renderer2
  ) {
    super();
  }

  @HostBinding('@routeAnimations') ProfileComponent: Component;
  @ViewChild('submitBtn') submitBtn: ElementRef;

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(''),
      newPassword: new FormControl(''),
      confirmPassword: new FormControl('')
    }, {
      validator: CustomValidators.mustMatch('newPassword', 'confirmPassword')
    });
    this.profileSubscription = this.usersService.getUser().subscribe(
      (result: UserModel): void => {
        this.profileForm.patchValue({
          ...result
        });
      }
    );
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.profileEditSubscription) {
      this.profileEditSubscription.unsubscribe();
    }
  }

  public onProfileSubmit(): void {
    this.profileSubmitAttempt = true;
    const { password, newPassword, confirmPassword } = this.profileForm.value;
    if (password.length > 0 || newPassword.length > 0 || confirmPassword.length > 0) {
      this.passwordChanged = true;
      this.addValidatorsAndValidate();
    } else {
      this.removeValidators();
    }
    if (this.profileForm.valid) {
      this.renderer.setAttribute(this.submitBtn.nativeElement, 'disabled', 'true');
      const {first_name, last_name, email } = this.profileForm.value;
      if (this.passwordChanged) {
        this.passwordChanged = false;
        this.profileEditSubscription = this.usersService.editUserPut({
          first_name,
          last_name,
          email,
          password: newPassword
        }).pipe(tap((): void => {
          this.resetPassswordValues();
          this.profileSubmitAttempt = false;
          this.renderer.removeAttribute(this.submitBtn.nativeElement, 'disabled');
        })).subscribe(
          (result): void => {
            this.toastrService.success(this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'PROFILE_CHANGED', 'MESSAGE']));
          },
          (err: HttpErrorResponse): void => {
            this.toastrService.error('Please try again.', 'Something went wrong!');
          }
        );
      } else {
        this.profileEditSubscription = this.usersService.editUserPatch({
          first_name,
          last_name,
          email
        }).pipe(tap((): void => {
          this.profileSubmitAttempt = false;
          this.renderer.removeAttribute(this.submitBtn.nativeElement, 'disabled');
        })).subscribe(
          (result): void => {
            this.toastrService.success(this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'PROFILE_CHANGED', 'MESSAGE']));
          },
          (err: HttpErrorResponse): void => {
            this.toastrService.error('Please try again.', 'Something went wrong!');
          }
        );
      }
    }
  }

  public onPasswordInput(): void {
    this.profileSubmitAttempt = false;
  }

  private addValidatorsAndValidate(): void {
    this.passwordChanged = true;
    this.profileForm.controls.password.setValidators([Validators.required, Validators.minLength(8)]);
    this.profileForm.controls.newPassword.setValidators([Validators.required, Validators.minLength(8)]);
    this.profileForm.controls.confirmPassword.setValidators([Validators.required]);
    this.profileForm.controls.password.updateValueAndValidity();
    this.profileForm.controls.newPassword.updateValueAndValidity();
    this.profileForm.controls.confirmPassword.updateValueAndValidity();
  }

  private removeValidators(): void {
    this.passwordChanged = false;
    this.profileForm.controls.password.clearValidators();
    this.profileForm.controls.newPassword.clearValidators();
    this.profileForm.controls.confirmPassword.clearValidators();
    this.profileForm.controls.password.updateValueAndValidity();
    this.profileForm.controls.newPassword.updateValueAndValidity();
    this.profileForm.controls.confirmPassword.updateValueAndValidity();
  }

  private resetPassswordValues(): void {
    this.profileForm.controls.password.setValue('');
    this.profileForm.controls.newPassword.setValue('');
    this.profileForm.controls.confirmPassword.setValue('');
  }

  public togglePassword(): void {
    this.passwordOpened = !this.passwordOpened;
  }

}
