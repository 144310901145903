import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ReviewsService } from 'src/app/services/reviews.service';
import { ReviewModel } from 'src/app/models/review.model';
import { ResultModel } from 'src/app/models/result.model';
import { fadeAnimation } from 'src/app/animations/fade.animation';

@Component({
  selector: 'app-home-reviews',
  templateUrl: './home-reviews.component.html',
  animations: [fadeAnimation]
})
export class HomeReviewsComponent implements OnInit, OnDestroy {

  public reviews: ReviewModel[] = [];
  private reviewsCountToShow = 6;
  private reviewsSubscription: Subscription;
  public reviewsCount: number;

  constructor(
    private reviewsService: ReviewsService
  ) { }

  ngOnInit(): void {
    this.reviewsSubscription = this.reviewsService.getReviews(1, this.reviewsCountToShow).subscribe(
      (result: ResultModel<ReviewModel>): void => {
        this.reviews = result.results;
      },
      (err: HttpErrorResponse): void => {
        this.reviews = [];
      }
    );
  }

  ngOnDestroy(): void {
    if (this.reviewsSubscription) {
      this.reviewsSubscription.unsubscribe();
    }
  }

}
