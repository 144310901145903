import { ReviewUser } from './user.model';
import { ProductModel } from './product.model';

export interface IReview {
  readonly wine: number;
  rate: number;
  comment: string;
  readonly created?: Date;
  readonly id?: number;
  isMine?: boolean;
}

export class ReviewForPost {
  constructor(
    public readonly wine: number,
    public readonly user: number,
    public readonly rate: number,
    public readonly comment: string
  ) {}
}

export interface ReviewModel {
  readonly wine: ProductModel;
  user: ReviewUser;
  rate: number;
  comment: string;
  readonly created: Date;
  readonly id: number;
  isMine?: boolean;
}

export interface ReviewModelWithMessage {
  message: string;
  title: string;
  data: ReviewModel;
}
