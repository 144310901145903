import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {

  @Input() maxSize = 5;
  @Output() pageChange = new EventEmitter<number>();

}
