import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IParam } from '../models/param.model';
import { isArray } from 'util';

/**
 * @description Gets token from current route.
 * @export
 * @param {ActivatedRoute} activatedRoute
 * @returns
 */
export function getTokenFromRoute(activatedRoute: ActivatedRoute): string {
  let token: string = null;
  activatedRoute.queryParams.subscribe(
    (params): void => {
      token = params.token;
    }
  );
  return token;
}

/**
 * @description Gets token from local storage.
 * @export
 * @returns
 */
export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

/**
 * @description Capitalize word.
 * @export
 * @param {string} str
 * @returns {string}
 */
export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
 * @description Converts any object to HttpParams.
 * @export
 * @param {IParam} obj
 * @returns {HttpParams}
 */
export function anyToHttpParams(obj: IParam): HttpParams {
  let param = new HttpParams();
  Object.keys(obj).forEach((key) => {
    if (!isArray(obj[key])) {
      param = param.set(key, obj[key] as string);
    } else {
      (obj[key] as string[]).forEach(par => {
        param = param.append(key, par);
      });
    }
  });
  return param;
}
