import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, OnInit, Renderer2 } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    private scrollBtn: HTMLDivElement;
    private navbarFixed: HTMLElement;
    private scrollTop: number;

    @ViewChild('wrapper') wrapper: ElementRef<HTMLDivElement>;

    @HostListener('window:scroll')
    onWindowScroll(): void {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docScrollBottom = this.scrollTop + window.innerHeight === document.body.clientHeight;

        if (this.scrollTop >= 10) {
            this.renderer.addClass(this.navbarFixed, 'navbar-sticked');
          } else {
            this.renderer.removeClass(this.navbarFixed, 'navbar-sticked');
        }

        if (this.scrollTop >= 150) {
            this.renderer.addClass(this.scrollBtn, 'visible');
        } else {
            this.renderer.removeClass(this.scrollBtn, 'visible');
        }
        if (docScrollBottom) {
            this.renderer.addClass(this.scrollBtn, 'active');
        } else {
            this.renderer.removeClass(this.scrollBtn, 'active');
        }
    }

    constructor(private renderer: Renderer2) { }

    ngOnInit(): void {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        // When reload page - check if page has offset
        if (this.scrollTop > 94) {
            this.renderer.addClass(this.navbarFixed, 'navbar-sticked');
        }
    }

    ngAfterViewInit(): void {
        this.navbarFixed = document.querySelector('.navbar-fixed');
        this.scrollBtn = this.renderer.createElement('div');
        this.renderer.addClass(this.scrollBtn, 'scroll-top');
        const icon = this.renderer.createElement('i');
        this.renderer.setAttribute(icon, 'class', 'icon icon-chevron-up');
        this.renderer.appendChild(this.scrollBtn, icon);
        this.renderer.appendChild(this.wrapper.nativeElement, this.scrollBtn);
        this.renderer.listen(this.scrollBtn, 'click', () => {
            if (document.documentElement.scrollTop) {
                this.animate(document.documentElement, 'scrollTop', '', this.scrollTop, document.body.offsetTop, 500, true);
            } else {
                this.animate(document.body, 'scrollTop', '', this.scrollTop, document.body.offsetTop, 500, true);
            }
        });
    }

    animate(elem: HTMLElement, style: string, unit: string, from: number, to: number, time: number, prop: boolean): void {
        if (elem) {
            const start = new Date().getTime(),
            timer = setInterval((): void => {
                const step = Math.min(1, (new Date().getTime() - start) / time);
                if (prop) {
                    elem[style] = (from + step * (to - from)) + unit;
                } else {
                    elem.style[style] = (from + step * (to - from)) + unit;
                }
                if (step === 1) {
                    clearInterval(timer);
                }
            }, 5);
            if (prop) {
                elem[style] = from + unit;
            } else {
                elem.style[style] = from + unit;
            }
        }
    }
}
