import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserModel } from '../models/user.model';
import { BaseRestService } from './base-rest.service';
import { AuthService } from './auth.service';
import { ResultModel } from '../models/result.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseRestService<UserModel> {

  constructor(
    protected http: HttpClient,
    private authService: AuthService
  ) {
    super(http);
  }

  public addUser(user: UserModel): Observable<ResultModel<UserModel>> {
    return this.create<UserModel>('users/register', user);
  }

  public getUser(): Observable<UserModel> {
    return this.getById('users', this.authService.getUserId());
  }

  public editUserPatch(data: any): Observable<UserModel> {
    return this.editPatch<UserModel>('users', this.authService.getUserId(), data);
  }

  public editUserPut(data: any): Observable<UserModel> {
    return this.editPut<UserModel>('users', this.authService.getUserId(), data);
  }

  public resetPassword(password: string, activation_token: string): Observable<any> {
    const data = {
      password,
      activation_token
    };
    return this.create('users/reset-password', data);
  }

  public forgotPassword(email: string): Observable<any> {
    const data = {
      email: email
    };
    return this.create('users/forgot-password', data);
  }
}
