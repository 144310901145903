import { Component, HostBinding } from '@angular/core';

import { routeAnimation } from 'src/app/animations/route.animation';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  animations: [routeAnimation]
})
export class AboutComponent {

  @HostBinding('@routeAnimations') AboutComponent: Component;

}
