import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseRestService } from './base-rest.service';
import { ContactMessageModel } from '../models/contact-message.model';

@Injectable({
  providedIn: 'root'
})
export class ContcatService extends BaseRestService<ContactMessageModel> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public sendMessage(message: ContactMessageModel): Observable<ContactMessageModel> {
    return this.postbyType<ContactMessageModel>('users/contact-us', message);
  }

}
