import { Component, Input, ChangeDetectorRef, AfterViewInit, DoCheck, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { ReviewModel } from 'src/app/models/review.model';
import { reviewToggleAnimation } from 'src/app/animations/review-toggle.animation';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  animations: [reviewToggleAnimation('reviewToggle', '88px')]
})
export class ReviewComponent implements AfterViewInit, DoCheck {

  // private reviewEditSubscription: Subscription;
  public expanded = false;
  public expandable = false;

  @Input() public reviewData: ReviewModel;
  @ViewChild('pre') pre: ElementRef;
  @HostListener('window:resize')
  onResize(): void {
    if (this.pre.nativeElement.scrollHeight <= 88) {
      this.expandable = false;
      this.expanded = false;
      this.renderer.removeClass(this.pre.nativeElement, 'overflow');
      this.renderer.setStyle(this.pre.nativeElement, 'height', 'auto');
    } else {
      this.expandable = true;
      if (!this.expanded) {
        this.renderer.addClass(this.pre.nativeElement, 'overflow');
        this.renderer.setStyle(this.pre.nativeElement, 'height', '88px');
      }
    }
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    if (this.pre.nativeElement.clientHeight > 88) {
      this.expandable = true;
      this.renderer.addClass(this.pre.nativeElement, 'overflow');
      this.renderer.setStyle(this.pre.nativeElement, 'height', '88px');
    }
    this.changeDetectorRef.detectChanges();
  }

  ngDoCheck(): void {
    this.changeDetectorRef.detectChanges();
  }

  // public onEdit() {
  //   if (this.reviewForm.valid) {
  //     this.review.comment = this.reviewForm.value.comment;
  //     this.reviewEditSubscription = this.reviewsService.editReview(this.review).subscribe(
  //       (result: ReviewModel) => {
  //         this.toastrService.success('Your review has been edited', 'Success');
  //         this.reviewForm.controls.comment.disable();
  //         this.editMode = false;
  //       },
  //       (err: HttpErrorResponse) => {
  //         this.toastrService.error('Please try again.', 'Something went wrong!');
  //         this.editMode = false;
  //       }
  //     );
  //   }
  // }

  // ngOnDestroy() {
  //   if (this.reviewEditSubscription) {
  //     this.reviewEditSubscription.unsubscribe();
  //   }
  // }

  public onToggle(): void {
    this.expanded = !this.expanded;
  }

  public onAnimationEvent(event: AnimationEvent): void {
    if (this.expandable) {
      if (event.toState && event.phaseName === 'done') {
        this.renderer.removeClass(this.pre.nativeElement, 'overflow');
      } else if (!event.toState && event.phaseName === 'start') {
        this.renderer.addClass(this.pre.nativeElement, 'overflow');
      }
    }
  }

}
