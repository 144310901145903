import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { LanguageConfig, ILanguage, LANG } from './language.config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnDestroy {

  public logined: boolean;
  private subscription: Subscription;
  private routerSubscription: Subscription;
  private menuExpanded = false;
  private searchExpanded = false;
  public languages: ILanguage[] = LanguageConfig;
  public lang = LANG.en;
  public searchValue: string;

  @ViewChild('menu') menu: ElementRef;
  @ViewChild('search') search: ElementRef;
  @ViewChild('searchBtn') searchBtn: ElementRef;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    public readonly translate: TranslateService
  ) {
    translate.addLangs(['en', 'am']);
    if (this.getanguage) {
      translate.use(this.getanguage);
      this.lang = LANG[this.getanguage];
    } else {
      translate.use('en');
      this.setLanguage = 'en';
    }

    this.routerSubscription = router.events.pipe(filter((event): boolean => event instanceof NavigationEnd)).subscribe(
      (event: NavigationEnd): void => {
        this.closeMenu();
      }
    );
  }

  ngOnInit(): void {
    this.subscription = this.authService.authenticatedSubject.subscribe(
      (value: boolean): void => {
        this.logined = value;
      }
    );

    this.logined = this.authService.isAuthenticated;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private set setLanguage (lang: string) {
    window.localStorage.setItem('lang', lang);
  }

  private get getanguage (): string {
    return window.localStorage.getItem('lang');
  }

  public onSearch($event: any): void {
    this.router.navigate(['/main/products'], { queryParams: { wine: this.searchValue } });
    $event.target.blur();
  }

  public onLogout(): void {
    this.authService.logout();
    this.router.navigate(['/home']);
  }

  public toggleMenu(): void {
    this.closeSearch();
    this.menuExpanded ? this.renderer.removeClass(this.menu.nativeElement, 'expanded') : this.renderer.addClass(this.menu.nativeElement, 'expanded');
    this.menuExpanded = !this.menuExpanded;
  }

  private closeMenu(): void {
    if (this.menuExpanded) {
      this.renderer.removeClass(this.menu.nativeElement, 'expanded');
      this.menuExpanded = false;
    }
  }

  public toggleSearch(): void {
    this.closeMenu();
    if (this.searchExpanded) {
      this.renderer.removeClass(this.search.nativeElement, 'open');
    } else {
      this.renderer.addClass(this.search.nativeElement, 'open');
    }
    this.searchExpanded = !this.searchExpanded;
  }

  public closeSearch(): void {
    if (this.searchExpanded) {
      this.searchValue = '';
      this.renderer.removeClass(this.search.nativeElement, 'open');
      this.searchExpanded = false;
      if (this.router.url.indexOf('/main/products') !== -1) {
        this.router.navigateByUrl('/main/products');
      }
    }
  }

  public onLangChange(): void {
    const leng = this.getKeyByValue(LANG, this.lang);
    this.translate.use(leng);
    this.setLanguage = leng;
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }
}
