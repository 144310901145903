import { Component, HostBinding } from '@angular/core';
import { routeAnimation } from 'src/app/animations/route.animation';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [
    routeAnimation
  ]
})
export class HomeComponent {

  @HostBinding('@routeAnimations') HomeComponent: Component;

}
