import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { UserModel } from 'src/app/models/user.model';
import { routeAnimation } from 'src/app/animations/route.animation';
import { toggleAnimation } from 'src/app/animations/toggle.animation';
import { FormValidation } from 'src/app/utils/forms';
import { ucfirst } from 'src/app/utils/helpers';
import { PreviousRouteService } from 'src/app/services/previouse-route.service';
import {TranslateService} from '@ngx-translate/core';
import {MyTranslateService} from '../../../services/my-translate.service';




@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  animations: [
    toggleAnimation('forgotSlide', 'forgot', 'signin'),
    routeAnimation
  ]
})
export class SigninComponent extends FormValidation implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public forgotForm: FormGroup;
  public isForgot = false;
  private loginSubscription: Subscription;
  private forgotSubscription: Subscription;
  public loginSubmitAttempt = false;
  public forgotSubmitAttempt = false;
  private previousRoutePattern = '/main/products/product/';
  private previousRoute: string;

  @HostBinding('@routeAnimations') hostAnimation: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private usersService: UsersService,
    private myTranslateService: MyTranslateService,
    private translate: TranslateService,
    private router: Router,
    private toastrService: ToastrService,
    private previousRouteService: PreviousRouteService
  ) {
    super();
  }

  ngOnInit(): void {
    this.previousRoute = this.previousRouteService.getPreviousUrl();
    this.loginForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });
    this.forgotForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.forgotSubscription) {
      this.forgotSubscription.unsubscribe();
    }
  }

  public expandForgot(): void {
    this.isForgot = !this.isForgot;
  }

  public onLoginSubmit(): void {
    this.loginSubmitAttempt = true;
    if (this.loginForm.valid) {
      const formData = this.loginForm.value;
      this.loginSubscription = this.authService.login(formData).subscribe(
        (result: UserModel): void => {
          this.resetForm(this.loginForm, 'loginSubmitAttempt');
          this.authService.setUser({
            first_name: result.first_name,
            last_name: result.last_name
          });
          this.authService.setToken(result.token);
          this.authService.authenticatedSubject.next(true);
          if (this.previousRouteService.getPreviousUrl().includes(this.previousRoutePattern)) {
            const productId = this.previousRoute.slice(this.previousRoute.lastIndexOf('/') + 1);
            this.router.navigate([`/main/products/product/${productId}`]);
          } else {
            this.router.navigate(['/home']);
          }
        },
        (err: HttpErrorResponse): void => {
          if (err.status === 400) {
            this.loginSubmitAttempt = false;
            this.toastrService.error(this.myTranslateService.translate(['SIGNIN', 'ERROR', 'TITLE']),
              this.myTranslateService.translate(['SIGNIN', 'ERROR', 'CREDENTIAL_ERROR']));
          } else{
            this.toastrService.error(this.myTranslateService.translate(['SIGNIN', 'ERROR', 'TITLE']),
              this.myTranslateService.translate(['SIGNIN', 'ERROR', 'MESSAGE']));
          }

        }
      );
    }
  }

  public onForgotSubmit(): void {
    this.forgotSubmitAttempt = true;
    if (this.forgotForm.valid) {
      const email = this.forgotForm.value.email;
      this.resetForm(this.forgotForm, 'forgotSubmitAttempt');
      this.forgotSubscription = this.usersService.forgotPassword(email).subscribe(
        (result): void => {
          this.toastrService.success(this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'FORGOT_PASSWORD', 'MESSAGE']),
            this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'FORGOT_PASSWORD', 'TITLE']));
        },
        (err: HttpErrorResponse): void => {
          if (err.status === 400) {
            for (const error of err.error) {
              this.toastrService.error(ucfirst(error));
            }
          } else if (err.status === 302) {
            this.toastrService.success(this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'FORGOT_PASSWORD', 'MESSAGE']),
              this.myTranslateService.translate(['TOASTR_MESSAGES', 'SUCCESS', 'FORGOT_PASSWORD', 'TITLE']));
          } else {
            this.toastrService.error(this.myTranslateService.translate(['SIGNIN', 'ERROR', 'TITLE']),
              this.myTranslateService.translate(['SIGNIN', 'ERROR', 'MESSAGE']));
          }
        }
      );
    }
  }

}
