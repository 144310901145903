import { trigger, transition, style, animate } from '@angular/animations';

export const routeAnimation =
  trigger('routeAnimations', [
    transition(':enter', [
      style({
        opacity: 0
      }),
      animate('.3s cubic-bezier(0.4, 0, 0.2, 1)', style({
        opacity: 1
      }))
    ])
  ]);
