import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IFilterConfig, productsFilterConfig } from '../components/main/products/products-filter/products-filter.config';
import { ICountries, ICities } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsFilterService {

  public filterConfig: IFilterConfig;
  public filterSubject = new Subject<boolean>();
  public filterOpened: boolean;
  public filterOpenedSubject = new Subject<boolean>();
  public countries: ICountries = {};
  public cities: ICities = {};

  constructor() {
    this.filterConfig = productsFilterConfig;
  }
}
