import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ISortConfig, productsSortConfig } from './products-sort.config';
import { ProductsFilterService } from 'src/app/services/products-filter.service';

@Component({
  selector: 'app-products-sortbar',
  templateUrl: './products-sortbar.component.html'
})
export class ProductsSortbarComponent {
  public active = true;
  public sortConfig: ISortConfig;
  @Output() changeSort = new EventEmitter<string>();
  @Input() parentForm: FormGroup;
  @Input() productsCount: number;
  @Input() productsPerPage: number;
  @Input() showAll: boolean;

  constructor(
    private productsFilterService: ProductsFilterService
  ) {
    this.sortConfig = productsSortConfig;
  }

  public onProductFilter(): void {
    this.active = true;
    this.changeSort.emit(String(this.active));
    this.productsFilterService.filterSubject.next(true);
  }

  public toggleFilter(): void {
    this.productsFilterService.filterOpenedSubject.next(!this.productsFilterService.filterOpened);
  }

  public changeFilter(): void {
    this.active = !this.active;
    this.changeSort.emit(String(this.active));
    this.productsFilterService.filterSubject.next(true);
  }

}
