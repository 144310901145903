import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ActivateUserService } from 'src/app/services/activate-user.service';
import { getTokenFromRoute } from 'src/app/utils/helpers';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  providers: [ActivateUserService]
})
export class ActivateUserComponent implements OnInit, OnDestroy {

  private activationSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private activateUserService: ActivateUserService
  ) { }

  ngOnInit() {
    const token = getTokenFromRoute(this.activatedRoute);
    if (token) {
      this.activationSubscription = this.activateUserService.activateUser({activation_token: token}).subscribe(
        (result): void => {
          if (result.message) {
            this.router.navigate(['/main/signin']);
          }
        },
        (err: HttpErrorResponse): void => {
          this.router.navigate(['/main/signup']);
        }
      );
    } else {
      this.router.navigate(['/main/signup']);
    }
  }

  ngOnDestroy() {
    if (this.activationSubscription) {
      this.activationSubscription.unsubscribe();
    }
  }

}
