import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UsersService } from 'src/app/services/users.service';
import { routeAnimation } from 'src/app/animations/route.animation';
import { CustomValidators } from 'src/app/utils/validators';
import { FormValidation } from 'src/app/utils/forms';
import { ucfirst } from 'src/app/utils/helpers';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  animations: [
    routeAnimation,
    trigger('successSlide', [
      state('signup', style({
        opacity: 1,
        height: '*'
      })),
      state('success', style({
        height: 0,
        opacity: 0
      })),
      transition('signup <=> success', [
        animate('.4s cubic-bezier(0.4, 0, 0.2, 1)')
      ])
    ]),
  ]
})
export class SignupComponent extends FormValidation implements OnInit, OnDestroy {

  public regForm: FormGroup;
  private signupSubscription: Subscription;
  public signupSubmitAttempt = false;
  public isSuccess = false;

  @HostBinding('@routeAnimations') hostAnimation: any;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.regForm = this.formBuilder.group({
      first_name: new FormControl(null, [ Validators.required, Validators.pattern(/[\u0561-\u0587\u0531-\u0556a-zA-Z0-9\s]+/) ]),
      last_name: new FormControl(null, [ Validators.required, Validators.pattern(/[\u0561-\u0587\u0531-\u0556a-zA-Z0-9\s]+/) ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, {
      validator: CustomValidators.mustMatch('password', 'confirmPassword')
    });
  }

  ngOnDestroy(): void {
    if (this.signupSubscription) {
      this.signupSubscription.unsubscribe();
    }
  }

  public onRegistrationSubmit (): void {
    this.signupSubmitAttempt = true;
    if (this.regForm.valid) {
      const formData = this.regForm.value;
      this. signupSubscription = this.usersService.addUser(formData).subscribe(
        (result): void => {
          this.resetForm(this.regForm, 'signupSubmitAttempt');
          this.isSuccess = true;
        },
        (err: HttpErrorResponse): void => {
          this.signupSubmitAttempt = false;
          this.isSuccess = false;
          if (err.status === 400) {
            for (const error in err.error) {
              if (err.error[error]) {
                this.toastrService.error(ucfirst(err.error[error][0]));
              }
            }
          } else {
            this.toastrService.error('Please try again.', 'Something went wrong!');
          }
        }
      );
    }
  }

}
