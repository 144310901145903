export interface ILanguage {
  lang: string;
  label: string;
}

export enum LANG {
  en = 'ENG',
  am = 'ARM'
}

export const LanguageConfig = <ILanguage[]>[
  {
    lang: 'en',
    label: 'ENG'
  },
  {
    lang: 'am',
    label: 'ARM'
  }
];
