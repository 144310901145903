import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// enviroment
import { environment } from 'src/environments/environment';

// modules
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

// pipes
import { RatingPipe } from './pipes/rating.pipe';
import { FullnamePipe } from './pipes/fullname.pipe';
import { MyTranslatePipe } from './pipes/my-translate.pipe';

// helpers
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { tokenGetter } from './utils/helpers';

// directives
import { FocusInvalidDirective } from './directives/focus-invalid.directive';

// components
import { AppComponent } from './app.component';

import { HomeComponent } from './components/home/home.component';
import { HeaderContentComponent } from './components/home/header-content/header-content.component';
import { HomeProductsComponent } from './components/home/home-products/home-products.component';
import { HomeReviewsComponent } from './components/home/home-reviews/home-reviews.component';

import { MainComponent } from './components/main/main.component';
import { MainHeaderComponent } from './components/main/main-header/main-header.component';
import { SigninComponent } from './components/main/signin/signin.component';
import { SignupComponent } from './components/main/signup/signup.component';
import { ResetPasswordComponent } from './components/main/reset-password/reset-password.component';
import { AboutComponent } from './components/main/about/about.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ProductsComponent } from './components/main/products/products.component';
import { ProductComponent } from './components/main/products/product/product.component';
import { ProductOverviewComponent } from './components/main/products/product/product-overview/product-overview.component';
import { ContactComponent } from './components/main/contact/contact.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { ReviewComponent } from './components/main/reviews/review/review.component';
import { ReviewsComponent } from './components/main/reviews/reviews.component';
import { ProductReviewsComponent } from './components/main/products/product/product-reviews/product-reviews.component';
import { ProductReviewComponent } from './components/main/products/product/product-reviews/product-review/product-review.component';
import { ProfileComponent } from './components/main/profile/profile.component';
import { ProductsFilterComponent } from './components/main/products/products-filter/products-filter.component';
import { ProductsSortbarComponent } from './components/main/products/products-sortbar/products-sortbar.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderContentComponent,
    HomeProductsComponent,
    HomeReviewsComponent,
    MainComponent,
    SigninComponent,
    MainHeaderComponent,
    AboutComponent,
    SignupComponent,
    NotfoundComponent,
    ResetPasswordComponent,
    ProductsComponent,
    ProductComponent,
    ProductOverviewComponent,
    ContactComponent,
    ActivateUserComponent,
    ReviewComponent,
    ProductReviewsComponent,
    ProductReviewComponent,
    RatingPipe,
    FullnamePipe,
    ProfileComponent,
    ReviewsComponent,
    FocusInvalidDirective,
    ProductsFilterComponent,
    ProductsSortbarComponent,
    MyTranslatePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.apiDomain],
        headerName: 'Authorization',
        authScheme: 'JWT '
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
