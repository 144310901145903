import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';
import { MatExpansionModule } from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { ClickOutsideModule } from 'ng-click-outside';

// componetns
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { PaginationComponent } from './pagination/pagination.component';

// helpers
import { HttpLoaderFactory } from '../utils/helpers';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxPaginationModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClickOutsideModule,
    ToastrModule.forRoot({timeOut: 10000})
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    PaginationComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NavbarComponent,
    FooterComponent,
    PaginationComponent,
    EllipsisModule,
    MatExpansionModule,
    Ng5SliderModule,
    NgSelectModule,
    NgxPaginationModule,
    ToastrModule,
    TranslateModule,
    ClickOutsideModule
  ]
})
export class SharedModule { }
